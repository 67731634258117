<template>
  <b-container>
    <b-row class="justify-content-center">
      <b-col
        cols="12"
        class="py-1"
      >
        <h1 class="text-center">
          Jobs
        </h1>
      </b-col>
      <b-col
        cols="12"
        lg="8"
        xl="10"
        class="py-3 text-center"
      >
        <b-row class="justify-content-center d-none">
          <b-col
            v-for="el in categories"
            :key="el.id"
            class="py-1 job-categories"
            cols="auto"
          >
            <a
              v-if="categoryIsAvailable(el.id)"
              @click="filter(el.id)"
            >
              <b-badge
                class="badge-glow"
                variant="primary"
              >
                <b>
                  {{ el.name }}
                </b>
                <fa-icon
                  v-if="closeFilterButton===true"
                  pack="fas"
                  icon="times"
                  color="danger"
                /></b-badge>
            </a>
          </b-col>
        </b-row>

      </b-col>
      <b-col
        class="py-5"
        cols="12"
      >
        <b-row class="justify-content-center">
          <b-col
            cols="12"
            sm="8"
            md="6"
            lg="4"
            class="py-1"
          >
            <div class="form-label-group">
              <b-form-input
                id="search-input"
                v-model="searchInput"
                placeholder="Search Jobs"
                type="search"
                :state="SearchStatus"
                @keyup.enter="search"
              />
              <label
                class="lp"
                for="search-input"
              >Search Jobs</label>
              <b-form-valid-feedback>
                About {{ jobs.length }} jobs results
              </b-form-valid-feedback>
              <b-form-invalid-feedback>
                No Data Available
              </b-form-invalid-feedback>
            </div>
          </b-col>
          <b-col
            cols="12"
            sm="4"
            md="2"
            lg="2"
            class="py-1 text-left"
          >
            <b-button
              class="w-100"
              variant="primary"
              @click="search"
            >
              search
            </b-button>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        v-for="tr in jobs"
        :key="tr.id"
        class="d-flex"
        cols="11"
        sm="8"
        md="5"
        lg="4"
        xl="3"
      >
        <b-card class="w-100">
          <b-card-title>
            <b-row>
              <b-col cols="10">
                <h2>{{ tr.id }}-{{ tr.job_title }}</h2>
              </b-col>
              <b-col
                class="d-flex justify-content-end"
                cols="2"
              >
                <a @click="SendRequestJobs(tr.id)">
                  <fa-icon
                    color="success"
                    pack="fas"
                    icon="envelope"
                  />
                </a>
              </b-col>
            </b-row>
          </b-card-title>
          <b-card-body>
            <b-row>
              <b-col
                cols="12"
              >
                <h3 class="section-title">
                  Description
                </h3>
                {{ tr.descriptions }}
              </b-col>
              <b-col
                cols="12"
                class="mt-2"
              >
                <h3 class="section-title">
                  Requirements
                </h3>
                {{ tr.requirements }}
              </b-col>
              <b-col
                class="mt-2"
                cols="12"
              >
                <h3 class="section-title">
                  Job Address
                </h3>
                {{ tr.job_address }}
              </b-col>
              <b-col
                cols="12"
                class="mt-2"
              >
                <h3 class="section-title">
                  Employment Type
                </h3>
                <vx-badge color="light-success">
                  {{ tr.employment_type }}
                </vx-badge>
              </b-col>
              <b-col
                class="mt-2"
                cols="12"
              >
                <h3 class="section-title">
                  Status Of The Job
                </h3>
                <vx-badge :color="tr.status==='unavailable'?'light-danger':'light-success'">
                  {{ tr.status }}
                </vx-badge>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col
        v-if="jobs.length===0"
        cols="12"
        class="text-center"
      >No Data Available</b-col>
    </b-row>
  </b-container>
</template>
<script>
export default {
  name: 'Jobs',
  data() {
    return {
      closeFilterButton: false,
      selectedCategories: '',
      searchInput: '',
      SearchStatus: null,
    }
  },
  computed: {
    jobs() {
      return this.$store.getters['company/global_jobs']
    },
    categories() {
      return this.$store.getters['category/cats']
    },
  },
  watch: {
    searchInput(val) {
      if (!val) {
        this.GetGlobalJobs()
        this.SearchStatus = null
      }
    },
  },
  created() {
    this.GetGlobalJobs()
    this.$store.dispatch('category/GetHomeData')
  },
  methods: {
    SendRequestJobs(val) {
      if (this.$store.getters['auth/GetAuth']) {
        this.$store.dispatch('company/RequestJobs', { job_id: val })
      } else {
        this.$router.push({
          name: 'login',
        })
      }
    },
    GetGlobalJobs() {
      this.$store.dispatch('company/GetGlobalJobs', { category_id: this.selectedCategories, search: this.searchInput }).then(response => {
        if (this.searchInput.length > 0) this.SearchStatus = response.data.data.length > 0
      })
    },
    categoryIsAvailable(val) {
      if (this.closeFilterButton === false) {
        return true
      }
      return val === this.selectedCategories
    },
    search() {
      if (this.searchInput === null) this.SearchStatus = null
      this.GetGlobalJobs()
    },
    filter(val) {
      if (this.closeFilterButton === false) {
        this.selectedCategories = val
        this.GetGlobalJobs()
        this.closeFilterButton = true
      } else {
        this.selectedCategories = null
        this.GetGlobalJobs()
        this.closeFilterButton = false
      }
    },
  },
}
</script>
